import React from "react";
import { Button, TextField } from "@mui/material";
import { CognitoUser } from "@aws-amplify/auth";

import { useAmplifyLogin } from "../../hooks/useAmplify";
import { useNavigate } from "react-router-dom";
import { useStoreValue } from "../../store";

import styled from "@emotion/styled";
import { white, label_color } from "../../theme/colors";

type LoginProps = {};

export const Login: React.FC<LoginProps> = () => {
  const [, updateStore] = useStoreValue();
  const navigate = useNavigate();
  const { email, setEmail, password, setPassword, loginWithAmplify } =
    useAmplifyLogin();

  const loginMethods = {
    handleLoginSuccess: (user: CognitoUser) => {
      updateStore({
        applicationLoginEmail: user.getUsername(),
      });
      navigate("/tenant");
    },
    handleLoginWithError: (error: any) => {
      updateStore({
        alertOpen: true,
        alertMessage: error.message,
        alertType: "error",
      });
    },
  };

  // Here we will call hooks to submit user login information
  const handleSubmitForm = (event: React.FormEvent): void => {
    event.preventDefault();
    loginWithAmplify({
      onSuccess: loginMethods.handleLoginSuccess,
      onError: loginMethods.handleLoginWithError,
    });
  };

  return (
    <form
      className="w-full h-full flex justify-center items-center"
      onSubmit={handleSubmitForm}
    >
      <LoginFormWrapper className="flex flex-col">
        <div className=" flex justify-center items-center pt-8 pb-6">
          <img src={require("../../imgs/Logo.png")} alt="" />
        </div>

        <div className="flex flex-col px-8">
          <LoginFormLabel>Email</LoginFormLabel>
          <TextField
            color="secondary"
            type="email"
            hiddenLabel
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            size="small"
          />

          <LoginFormLabel className="pt-3">Password</LoginFormLabel>
          <TextField
            color="secondary"
            type="password"
            hiddenLabel
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            size="small"
          />
        </div>

        <div className="px-8 flex flex-grow justify-end items-center">
          <Button
            color="secondary"
            disabled={!email || !password}
            type="submit"
            size="small"
            variant="contained"
            disableRipple
            disableElevation
          >
            Sign In
          </Button>
        </div>
      </LoginFormWrapper>
    </form>
  );
};

const LoginFormWrapper = styled.div`
  width: 390px;
  height: 288px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: ${white};
`;
const LoginFormLabel = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${label_color};
`;
