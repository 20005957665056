import { useState, useCallback, useLayoutEffect } from "react";

export interface DimensionObject {
  width: number;
  height: number;
}

export type UseDimensionsHook = [(node: HTMLElement) => void, DimensionObject];

export interface UseDimensionsArgs {
  liveMeasure?: boolean;
}

function getDimensionObject(node: HTMLElement | null): DimensionObject | void {
  if (node) {
    const rect = node.getBoundingClientRect();

    return {
      width: rect.width,
      height: rect.height,
    };
  }
}

function useDimensions({
  liveMeasure = true,
}: UseDimensionsArgs = {}): UseDimensionsHook {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [node, setNode] = useState<null | HTMLElement>(null);

  const ref = useCallback((node: HTMLElement) => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() => {
          const result = getDimensionObject(node);
          if (result) {
            setDimensions(result);
          }
        });
      measure();

      if (liveMeasure) {
        window.addEventListener("resize", measure);
        window.addEventListener("scroll", measure);

        return () => {
          window.removeEventListener("resize", measure);
          window.removeEventListener("scroll", measure);
        };
      }
    }
  }, [liveMeasure, node]);

  // @ts-ignore
  return [ref, dimensions];
}

export default useDimensions;
