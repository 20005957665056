import { Tender } from "../types/order.type";
import moment from "moment";

export const getTenderInfomation = ({
  startDate,
  tenders,
  tenderTypes,
}: {
  startDate: string;
  tenders: Tender[];
  tenderTypes: string[];
}) => {
  let shortestRefundableDays;
  let remainingDays;
  let latestRefundDate;
  let tendersName;

  if (tenders && tenderTypes) {
    const availableTenders: (Tender | undefined)[] = tenderTypes.map(
      (tenderType) => {
        return tenders.find((d) => d.tender_type === tenderType);
      }
    );

    (availableTenders as Tender[])
      .filter((x) => x !== undefined)
      .sort((a, b) => {
        return a.refundable_days - b.refundable_days;
      });

    tendersName = availableTenders.map((t) => t?.tender_display_name);

    shortestRefundableDays =
      availableTenders[availableTenders.length - 1]?.refundable_days;

    latestRefundDate = moment(new Date(startDate))
      .add(shortestRefundableDays, "d")
      .format("DD MMM, yyyy");

    remainingDays = moment().to(latestRefundDate);

    return {
      shortestRefundableDays,
      latestRefundDate,
      remainingDays,
      tendersName,
    };
  }
  return {
    shortestRefundableDays: "-",
    remainingDays: "-",
    latestRefundDate: "-",
    tendersName: [],
  };
};
