/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useStoreValue } from "../store";
import { FetchOrdersProps } from "../types/order.type";
import { Api } from "../_api";

export const useOrders = () => {
  const [storeState, updateStore] = useStoreValue();

  useEffect(() => {
    fetchTenders();
  }, []);

  useEffect(() => {
    // Call fetchRecords only if the localstorage has some date stored in it.
    // Localstorage is empty when the user logs out
    if (window.localStorage.length > 0) fetchOrders({});
  }, [
    storeState.currentPageNumber,
    storeState.currentPageSize,
    storeState.currentFilterDate,
    storeState.currentFilterSkus,
  ]);

  // Fetch Tenders
  const fetchTenders = async () => {
    updateStore({
      tendersLoading: true,
    });
    try {
      const result = await Api.getTenders();

      updateStore({
        tendersData: result.data.tenders,
        tendersLoading: false,
      });
    } catch (err) {
      updateStore({
        tendersError: err,
        tendersLoading: false,
      });
    }
  };

  // Fetch Orders
  const fetchOrders = async ({
    page_size = storeState.currentPageSize,
    page_number = storeState.currentPageNumber,
    to_order_placed_date = storeState.currentFilterDate,
    web_sku_id = storeState.currentFilterSkus,
  }: FetchOrdersProps) => {
    updateStore({
      orderPageLoading: true,
    });

    try {
      const result = await Api.getOrders({
        page_size,
        page_number,
        to_order_placed_date,
        web_sku_id,
      });
      if (result.data.orders) {
        updateStore({
          orderPageData: result.data,
          orderPageLoading: false,
        });
      } else {
        updateStore({
          alertOpen: true,
          alertMessage: result.data.msg,
          alertType: "error",
        });
      }
    } catch (err) {
      updateStore({
        orderPageError: err,
        orderPageLoading: false,
      });
    }
  };

  return { fetchOrders };
};
