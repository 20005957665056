import axios from "axios";
import { DEFAULT_MESSAGE } from "../constant";
import { FetchOrdersProps } from "../types/order.type";

export class Api {
  static getOrders({
    page_size,
    page_number,
    to_order_placed_date,
    web_sku_id,
  }: FetchOrdersProps) {
    return axios.get(
      `${process.env.REACT_APP_ENDPOINT}/orders?order_status=provisional&response_page_size=${page_size}&page_number=${page_number}`,
      {
        headers: {
          Authorization: window.sessionStorage.getItem("authorization") || "",
          Tenant: window.sessionStorage.getItem("tenant") || "",
        },
        params: {
          to_order_placed_date,
          web_sku_id,
        },
      }
    );
  }

  static getTenders() {
    return axios.get(`${process.env.REACT_APP_ENDPOINT}/settings/tenders`, {
      headers: {
        Authorization: window.sessionStorage.getItem("authorization") || "",
        Tenant: window.sessionStorage.getItem("tenant") || "",
      },
    });
  }

  static deleteOrder({
    order_id,
    skus,
    cancel_reason,
  }: {
    order_id: string;
    skus: {
      web_sku_id: string;
      quantity: number;
    }[];
    cancel_reason: string;
  }) {
    return axios.post(
      `${process.env.REACT_APP_ENDPOINT}/orders/${order_id}/item-cancel`,
      {
        skus,
        cancel_reason: cancel_reason || DEFAULT_MESSAGE,
      },
      {
        headers: {
          Authorization: window.sessionStorage.getItem("authorization") || "",
          Tenant: window.sessionStorage.getItem("tenant") || "",
        },
      }
    );
  }

  static cancelOrders() {}
}

// Authorization: window.sessionStorage.getItem("authorization") || "",
// Tenant: window.sessionStorage.getItem("tenant") || "",
