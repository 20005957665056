import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Layout } from "./components/Layout";
import { Login } from "./views/login";
import { Orders } from "./views/orders";
import { useFetchAuth } from "./hooks/useAmplify";
import { useStoreValue } from "./store";
import { CognitoUser } from "@aws-amplify/auth";
import { Tenant } from "./views/tenant";

// We will use a private route to protect orders page
const AppRoutes = () => {
  const [, updateStore] = useStoreValue();
  const navigate = useNavigate();
  const { fetchAuthAsync } = useFetchAuth();

  const authMethods = {
    handleAuthSuccess: (user: CognitoUser) => {
      updateStore({
        applicationLoginEmail: user.getUsername(),
      });

      navigate("/tenant");
    },
    handleAuthWithError: (_error: any) => {
      navigate("/login");
    },
  };

  useEffect(() => {
    fetchAuthAsync({
      onSuccess: authMethods.handleAuthSuccess,
      onError: authMethods.handleAuthWithError,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <Orders />
          </Layout>
        }
      />

      <Route
        path="/tenant"
        element={
          <Layout>
            <Tenant />
          </Layout>
        }
      />

      <Route
        path="/login"
        element={
          <Layout>
            <Login />
          </Layout>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
