import { Button, CircularProgress, Skeleton } from "@mui/material";
import { PaginationData } from "../../../types/order.type";
import getNumberRange from "../../../utils/getNumberRange";

type OrdersControlBarProps = {
  loading: boolean;
  pagination?: PaginationData;
  orderAmount: number;
  handleToggleConfirmCancellation: () => void;
};

export const OrdersControlBar: React.FC<OrdersControlBarProps> = ({
  loading,
  pagination,
  orderAmount,
  handleToggleConfirmCancellation,
}) => {
  const orderAmountRange = getNumberRange({
    pageNumber: pagination?.current_page_number,
    pageSize: pagination?.response_page_size,
  });

  return (
    <div className="flex justify-between items-center mt-10 mb-3">
      <div className="comestri-subtext-info">
        {pagination?.total_number_of_records ? (
          `${orderAmountRange.start} - ${
            pagination?.total_number_of_records
              ? orderAmountRange.end > pagination?.total_number_of_records
                ? pagination?.total_number_of_records
                : orderAmountRange.end
              : 0
          } of ${pagination?.total_number_of_records ?? 0}`
        ) : (
          <Skeleton width={100} />
        )}
      </div>
      <Button
        disabled={loading || !orderAmount}
        color="secondary"
        type="submit"
        size="small"
        variant="contained"
        disableRipple
        disableElevation
        onClick={handleToggleConfirmCancellation}
      >
        {loading ? (
          <CircularProgress size={14} color="secondary" />
        ) : (
          `Cancel ${orderAmount} Orders`
        )}
      </Button>
    </div>
  );
};
