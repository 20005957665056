import { Snackbar } from "@mui/material";
import { useAlert } from "./hooks/useAlert";
import AppRoutes from "./AppRoutes";

const App = () => {
  const { AlertBuilder } = useAlert();
  return (
    <>
      {AlertBuilder()}
      <AppRoutes />
    </>
  );
};

export default App;
