import _merge from "lodash/merge";
import _cloneDeep from "lodash/cloneDeep";
import { getSessionStorage } from "../utils/sessionStorage";
import { storageStateKey } from "./store";
import { OrdersPayload } from "../types/order.type";
import {
  CancellationFail,
  CancellationPayload,
} from "../types/cancellation.type";

export type InitialStateType = {
  applicationError: null | string;
  applicationLoginEmail: string | null;
  orderPageData: OrdersPayload | null;
  orderPageLoading: boolean;
  orderPageError: any;

  currentPageNumber: number;
  currentPageSize: number;
  currentFilterDate: string;
  currentFilterSkus: string;

  orderCancellationPayload: CancellationPayload[];
  orderCancellationFails: {
    [order_id: string]: CancellationFail[];
  };
  orderCancellationLoading: boolean;
  orderCancellationReason: string;
  orderCancellationError: any;

  tenantName: null | string;
  tenantId: null | string;
};

export const defaultTenantStore = {
  tenantName: null,
  tenantId: null,
};

export const defaultOrderStore = {
  orderPageData: null,
  orderPageLoading: false,
  orderPageError: null,
};

export const defaultPaginationStore = {
  currentPageNumber: 1,
  currentPageSize: 50,
  currentFilterDate: "",
  currentFilterSkus: "",
};

export const defaultCancellationOrderStore = {
  orderCancellationPayload: [],
  orderCancellationFails: {},
  orderCancellationLoading: false,
  orderCancellationReason: "",
  orderCancellationError: null,
};

export const defaultTenderStore = {
  tendersData: null,
  tendersLoading: false,
  tendersError: null,
};

export const defaultAlertStore = {
  alertOpen: false,
  alertMessage: "",
  alertType: "error",
};

export const defaultStore: InitialStateType = {
  applicationError: null,
  applicationLoginEmail: null,
  ...defaultOrderStore,
  ...defaultPaginationStore,
  ...defaultCancellationOrderStore,
  ...defaultTenderStore,
  ...defaultTenantStore,
  ...defaultAlertStore,
};

const storageState = getSessionStorage(storageStateKey);

export const initialState = _cloneDeep(
  storageState ? _merge({}, defaultStore, storageState) : defaultStore
);
