import { Checkbox as MUICheckbox, CheckboxProps } from "@mui/material";
import { FC } from "react";

import { CheckboxIcon, CheckboxChecked, CheckboxIndeterminate } from "./Icons";

const Checkbox: FC<CheckboxProps> = ({ ...props }) => {
  return (
    <MUICheckbox
      {...props}
      icon={<CheckboxIcon />}
      checkedIcon={<CheckboxChecked />}
      indeterminateIcon={<CheckboxIndeterminate />}
    />
  );
};

export default Checkbox;
