import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CloseIcon, SearchIcon } from "./Icons";

type ChipsBoxProps = {
  id?: string;
  chips: string[];
  disabled: boolean;
  setChips: Dispatch<SetStateAction<string[]>>;
};

const ChipsBox: FC<ChipsBoxProps> = ({ id, chips, disabled, setChips }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      if (chips.length === 0) {
        const clearIconDiv = containerRef.current.getElementsByClassName(
          "MuiAutocomplete-endAdornment"
        )[0];

        const clearButton = clearIconDiv.getElementsByTagName("button")[0];
        if (clearButton) {
          clearButton.click();
          // @ts-ignore
          inputRef?.current?.blur();
        }
      }
    }
  }, [chips, disabled]);

  const [focus, setFocus] = useState(false);

  const handleSetChips = (_e: React.ChangeEvent<unknown>, value: string[]) => {
    setChips(value);
  };

  const handleOnFocus = () => {
    setFocus(true);
  };

  const handleOnBlur = () => {
    setFocus(false);
  };

  return (
    <div className="chips-box" style={{ width: focus ? "300px" : "220px" }}>
      <Autocomplete
        ref={containerRef}
        disabled={disabled}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        multiple
        // disableClearable
        onChange={handleSetChips}
        options={[]}
        freeSolo
        limitTags={1}
        renderTags={(value: readonly string[], getTagProps) => {
          return value.map((option: string, index: number) => (
            <Chip
              size="small"
              deleteIcon={
                <div>
                  <CloseIcon />
                </div>
              }
              label={option}
              {...getTagProps({ index })}
            />
          ));
        }}
        renderInput={(params) => {
          return (
            <TextField
              inputRef={inputRef}
              {...params}
              fullWidth
              id={id || `chips-box-${uuidv4()}`}
              variant="outlined"
              size="small"
              color="primary"
              placeholder={chips.length ? "" : "Search SKUs"}
              InputProps={{
                ...params.InputProps,
                startAdornment: [
                  <div key={"-1"} className="mr-1">
                    <SearchIcon />
                  </div>,
                  params.InputProps.startAdornment,
                ],
              }}
            />
          );
        }}
      />
    </div>
  );
};
export default ChipsBox;
