import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { ListItemText, MenuItem, CircularProgress } from "@mui/material";
import { useStoreValue } from "../../store";

import styled from "@emotion/styled";
import { white } from "../../theme/colors";
import { useNavigate } from "react-router-dom";
import { ArrowIcon } from "../../components/Icons";
import { useFetchTenant, TenantType } from "../../hooks/useFetchTenant";

type TenantProps = {};

export const Tenant: React.FC<TenantProps> = () => {
  const [, updateStore] = useStoreValue();
  const navigate = useNavigate();

  const { tenants, loading } = useFetchTenant();

  useEffect(() => {
    const hasTenant = window.sessionStorage.getItem("tenant");
    Auth.currentSession().then((session: any) => {
      window.sessionStorage.setItem(
        "authorization",
        `Bearer ${session["idToken"].jwtToken}`
      );
    });

    if (hasTenant) navigate("/");

    if (tenants.length === 1) {
      updateStore({
        tenantName: tenants[0].name,
        tenantId: tenants[0].id,
      });

      window.sessionStorage.setItem("tenant", tenants[0].name);

      navigate("/");
    }
  }, [tenants]);

  const handleSelectedTenant = (tenant: TenantType) => {
    updateStore({
      tenantName: tenant.name,
      tenantId: tenant.id,
    });

    window.sessionStorage.setItem("tenant", tenant.name);

    navigate("/");
  };

  if (loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <TenantWrapper>
          <div className="flex justify-center items-center pt-8 pb-6">
            <img src={require("../../imgs/Logo.png")} alt="" />
          </div>
          <div className="flex justify-center items-center my-4">
            <CircularProgress size={14} color="secondary" />
          </div>
        </TenantWrapper>
      </div>
    );
  }

  return (
    <div className="w-full h-full flex justify-center items-center">
      <TenantWrapper>
        <div className="flex justify-center items-center pt-8 pb-6">
          <img src={require("../../imgs/Logo.png")} alt="" />
        </div>

        <div className="font-semibold text-base px-8">Select Tenant</div>

        <div className="mx-8 my-4 max-h-72 overflow-auto">
          {tenants.map((tenant, key) => {
            return (
              <MenuItem
                key={key}
                className="h-14"
                onClick={() => handleSelectedTenant(tenant)}
              >
                <ListItemText>
                  <p className="font-light text-sm">{tenant.name}</p>
                </ListItemText>
                <ArrowIcon />
              </MenuItem>
            );
          })}
        </div>
      </TenantWrapper>
    </div>
  );
};

const TenantWrapper = styled.div`
  width: 390px;
  /* min-height: 288px; */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: ${white};
`;
