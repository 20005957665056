import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { forwardRef, SyntheticEvent, useEffect, useState } from "react";
import { useStoreValue } from "../store";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  // eslint-disable-next-line react/jsx-no-undef
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const useAlert = () => {
  const [storeState, updateStore] = useStoreValue();
  useEffect(() => {
    if (storeState.alertOpen) {
      setTimeout(() => {
        updateStore({
          alertOpen: false,
        });
      }, 4000);
    }
  });

  const AlertBuilder = () => {
    return (
      <Snackbar
        open={storeState.alertOpen}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity={storeState.alertType}>{storeState.alertMessage}</Alert>
      </Snackbar>
    );
  };

  return { AlertBuilder };
};
