import { useState } from "react";
import { Button, Chip, CircularProgress } from "@mui/material";
import moment from "moment";
import ChipsBox from "../../../components/ChipsBox";
import DateTimerPicker from "../../../components/DateTimerPicker";
import styled from "@emotion/styled";
import { useStoreValue } from "../../../store";

type OrdersFilterBarProps = {
  loading: boolean;
};

export const OrdersFilterBar: React.FC<OrdersFilterBarProps> = ({
  loading,
}) => {
  const [, updateStore] = useStoreValue();
  const [chips, setChips] = useState<string[]>([]);
  const [time, setTime] = useState<Date | null>(null);

  const [filterChips, setFilterChips] = useState<string[]>([]);
  const [filterTime, setFilterTime] = useState<Date | null>(null);

  const handleFilterOrders = () => {
    setFilterChips(chips);
    setFilterTime(time);

    updateStore({
      currentPageNumber: 1,
      currentFilterDate: time ? time.toISOString().split(".")[0] + "Z" : "",
      currentFilterSkus: chips ? chips.join(",") : "",
    });

    setChips([]);
    setTime(null);
  };

  const handleClearOrders = () => {
    updateStore({
      currentPageNumber: 1,
      currentFilterDate: "",
      currentFilterSkus: "",
    });

    setFilterChips([]);
    setFilterTime(null);
  };

  return (
    <div>
      <div className="flex items-stretch">
        <p className="comestri-subtitle">Orders</p>

        <div className="flex-grow"></div>

        <div className="flex items-stretch">
          <p className="comestri-subtext">Orders Placed Before</p>

          <InputWrapper className="ml-4 mr-2">
            <DateTimerPicker disabled={loading} time={time} setTime={setTime} />
          </InputWrapper>

          <div className="mr-2">
            <ChipsBox disabled={loading} chips={chips} setChips={setChips} />
          </div>

          <Button
            disabled={(!chips.length && !time) || loading}
            color="secondary"
            type="submit"
            size="small"
            variant="contained"
            disableRipple
            disableElevation
            onClick={handleFilterOrders}
          >
            {loading ? (
              <CircularProgress size={14} color="secondary" />
            ) : (
              `Search`
            )}
          </Button>
        </div>
      </div>

      {/* The filter result records */}

      <div className="flex item-center pt-4">
        <div className="comestri-subtext pr-2">Placed before:</div>
        <div className="comestri-subtext pr-10">
          {filterTime ? (
            <Chip
              size="small"
              label={moment(new Date(filterTime)).format(
                "DD MMM, yyyy hh:mm A"
              )}
            />
          ) : (
            // We will move it to top level and set it after got data
            moment(new Date()).format("DD MMM, yyyy hh:mm A") // mock now
          )}
        </div>
        <div className="comestri-subtext pr-2">SKUs:</div>
        <div className="comestri-subtext pr-10">
          {filterChips?.length
            ? filterChips.map((chip: string, key: number) => {
                return <Chip key={key} size="small" label={chip} />;
              })
            : "All SKUs"}
        </div>
        {(filterChips.length || filterTime) && (
          <Button
            variant="text"
            size="small"
            color="secondary"
            disableElevation
            disableFocusRipple
            disableRipple
            onClick={handleClearOrders}
          >
            Clear
          </Button>
        )}
      </div>
    </div>
  );
};

const InputWrapper = styled.div`
  width: 220px;
`;
