import React, { useCallback } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import styled from "@emotion/styled";

import useDimensions from "../../../hooks/useDimensions";
import Checkbox from "../../../components/Checkbox";

import { theme_secondary_100 } from "../../../theme/colors";
import { MultipleSkusOrder } from "./order-table-multiple-item";
import { SignleSkuOrder } from "./order-table-signle-item";
import {
  CancellationFail,
  CancellationPayload,
} from "../../../types/cancellation.type";
import { Order, SelectedOrderSKUs, SKU } from "../../../types/order.type";
import { useStoreValue } from "../../../store";

type OrdersTableProps = {
  data?: Order[];
  cancellationFailData: {
    [order_id: string]: CancellationFail[];
  };
  loading: boolean;
  selectedSKUs: {} | SelectedOrderSKUs;
  setSelectedSKUs: React.Dispatch<React.SetStateAction<{} | SelectedOrderSKUs>>;
};

export const OrdersTable: React.FC<OrdersTableProps> = ({
  data = [],
  cancellationFailData,
  loading,
  selectedSKUs,
  setSelectedSKUs,
}) => {
  const [ref, dimensions] = useDimensions();
  const [storeState, updateStore] = useStoreValue();

  const globalCheck = () => {
    const allSkusOnThisPage: string[] = data.reduce(
      (pre: string[], cur: Order) => {
        const currentOrderSkus: string[] = cur.skus.map(
          (sku) => sku.web_sku_id
        );
        return [...pre, ...currentOrderSkus];
      },
      []
    );

    let allSelectedSKUsLength = 0;

    for (let key in selectedSKUs) {
      const currentSkus: string[] =
        selectedSKUs[key as keyof typeof selectedSKUs];
      allSelectedSKUsLength += currentSkus.length;
    }

    const checked = !!allSelectedSKUsLength
      ? allSelectedSKUsLength === allSkusOnThisPage.length
      : false;

    const ieIndeterminate = !!allSelectedSKUsLength
      ? allSelectedSKUsLength < allSkusOnThisPage.length
      : false;

    return {
      checked,
      ieIndeterminate,
    };
  };

  const handleCollectSKU = useCallback(
    (order_id: string, web_sku_id: string) => {
      const copySelectedSKUs: SelectedOrderSKUs = selectedSKUs;
      const currentOrder =
        copySelectedSKUs[order_id as keyof typeof selectedSKUs];

      if (currentOrder) {
        if (currentOrder.includes(web_sku_id)) {
          copySelectedSKUs[order_id as keyof typeof selectedSKUs] = [
            ...currentOrder.filter((sku) => sku !== web_sku_id),
          ];
        } else {
          copySelectedSKUs[order_id as keyof typeof selectedSKUs] = [
            ...currentOrder,
            web_sku_id,
          ];
        }
      } else {
        copySelectedSKUs[order_id as keyof typeof selectedSKUs] = [web_sku_id];
      }

      if (!copySelectedSKUs[order_id as keyof typeof selectedSKUs].length) {
        delete copySelectedSKUs[order_id as keyof typeof selectedSKUs];
      }

      setSelectedSKUs({ ...copySelectedSKUs });
    },
    [selectedSKUs, setSelectedSKUs]
  );

  const handleCollectSKUs = useCallback(
    (
      order_id: string,
      web_sku_ids: string[],
      indeterminate: boolean,
      allChecked: boolean
    ) => {
      const copySelectedSKUs: SelectedOrderSKUs = selectedSKUs;

      if (allChecked) {
        delete copySelectedSKUs[order_id as keyof typeof selectedSKUs];
        setSelectedSKUs({ ...copySelectedSKUs });
      }
      if (indeterminate || (!indeterminate && !allChecked)) {
        copySelectedSKUs[order_id as keyof typeof selectedSKUs] = web_sku_ids;
        setSelectedSKUs({ ...copySelectedSKUs });
      }
    },
    [selectedSKUs, setSelectedSKUs]
  );

  const handleAllOrdersSkus = useCallback(
    (indeterminate: boolean, allChecked: boolean) => {
      if (allChecked) {
        setSelectedSKUs({});
      }

      if (indeterminate || (!indeterminate && !allChecked)) {
        const allCheckedOrdersSkus: SelectedOrderSKUs = {};

        const copyData = data;

        copyData.forEach((order: Order) => {
          allCheckedOrdersSkus[order.order_id as keyof typeof selectedSKUs] =
            order.skus.reduce((pre: string[], cur: SKU) => {
              return [...pre, cur.web_sku_id];
            }, []);
        });

        setSelectedSKUs(allCheckedOrdersSkus);
      }
    },
    [data, setSelectedSKUs]
  );

  const removeOrderCancellationFailItem = (
    orderId: string,
    webSkuId?: string
  ) => {
    // Logic for orderCancellationFails
    let tempStoreState = storeState;
    // Remove error failure for only child order if web SKU exists.
    if (webSkuId) {
      tempStoreState.orderCancellationFails[orderId] =
        tempStoreState.orderCancellationFails[orderId].filter(
          (failInfo: CancellationFail) => {
            return failInfo.web_sku_id !== webSkuId;
          }
        );
    }
    // Remove error failure for parent if web SKU is not given or no child orders exist.
    if (
      !webSkuId ||
      tempStoreState.orderCancellationFails[orderId].length === 0
    ) {
      delete tempStoreState.orderCancellationFails[orderId];
    }
    updateStore(tempStoreState);
  };

  const removeOrderCancellationPayloadItem = (
    orderId: string,
    webSkuId?: string
  ) => {
    // Logic for orderCancellationPayload
    let tempStoreState = storeState;
    tempStoreState.orderCancellationPayload.forEach(
      (orderInfo: CancellationPayload, index: number) => {
        if (orderInfo.order_id === orderId) {
          if (!webSkuId) {
            // Remove parent and child fail info.
            orderInfo.fail_cases = [];
            // Decrease fail items count.
            orderInfo.metadata.cancel_failure = 0;
            // Decrease total items count.
            orderInfo.metadata.total_items = orderInfo.metadata.cancel_success;
          } else {
            // Remove only child fail info.
            orderInfo.fail_cases = orderInfo.fail_cases.filter(
              (failInfo: CancellationFail) => {
                return failInfo.web_sku_id !== webSkuId;
              }
            );
            // Decrease fail items count.
            orderInfo.metadata.cancel_failure--;
            // Decrease total items count.
            orderInfo.metadata.total_items--;
          }
        }

        // If no fail and no success items exist, remove order from payload entirely.
        if (orderInfo.metadata.total_items == 0) {
          tempStoreState.orderCancellationPayload.splice(index, 1);
        }
      }
    );
    updateStore(tempStoreState);
  };

  const handleClearError = (orderId: string, webSkuId?: string) => {
    // Remove order from orderCancellationFails
    removeOrderCancellationFailItem(orderId, webSkuId);
    // Remove order from orderCancellationPayload
    removeOrderCancellationPayloadItem(orderId, webSkuId);
  };

  return (
    // @ts-ignore
    <div className="flex-grow overflow-auto" ref={ref}>
      <Paper sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ height: "100%", maxHeight: dimensions.height }}>
          {loading ? (
            <div className="flex h-full justify-center items-center">
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell size="small"></TableCell>
                  <TableCell>
                    <Checkbox
                      disabled={loading}
                      checked={globalCheck().checked}
                      indeterminate={globalCheck().ieIndeterminate}
                      onChange={() =>
                        handleAllOrdersSkus(
                          globalCheck().ieIndeterminate,
                          globalCheck().checked
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="left">Order Number</TableCell>
                  <TableCell align="left">Order Placed Date</TableCell>
                  <TableCell align="left">SKU</TableCell>
                  <TableCell align="right">QTY</TableCell>
                  <TableCell align="right">Tenders</TableCell>
                  <TableCell align="right">Latest Refund Date</TableCell>
                  <TableCell align="right">Remaining Days</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((order: Order, key) => {
                  const itemQty = order.skus.reduce((pre, cur) => {
                    return (pre += cur.quantity);
                  }, 0);

                  return order.skus.length > 1 ? (
                    <MultipleSkusOrder
                      key={key}
                      disabled={loading}
                      order={order}
                      cancellationFailData={cancellationFailData}
                      itemQty={itemQty}
                      selectedSKUs={selectedSKUs}
                      handleCollectSKU={handleCollectSKU}
                      handleCollectSKUs={handleCollectSKUs}
                      handleClearError={handleClearError}
                    />
                  ) : (
                    <SignleSkuOrder
                      key={key}
                      disabled={loading}
                      order={order}
                      cancellationFailData={cancellationFailData}
                      itemQty={itemQty}
                      selectedSKUs={selectedSKUs}
                      handleCollectSKU={handleCollectSKU}
                      handleClearError={handleClearError}
                    />
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Paper>
    </div>
  );
};

export const OrderTableSubItem = styled.tr`
  background-color: ${theme_secondary_100};
`;
