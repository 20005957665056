import styled from "@emotion/styled";
import { page_background } from "../theme/colors";

type LayoutProps = {
  children: React.ReactNode;
};

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <LayoutWrapper className="w-screen h-screen">{children}</LayoutWrapper>
  );
};

const LayoutWrapper = styled.div`
  background-color: ${page_background};
`;
