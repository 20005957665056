import { Pagination, Skeleton } from "@mui/material";
import { useStoreValue } from "../../../store";
import { PaginationData } from "../../../types/order.type";

import getNumberRange from "../../../utils/getNumberRange";

type OrdersTablePaginationProps = {
  pagination?: PaginationData;
  loading: boolean;
};

export const OrdersTablePagination: React.FC<OrdersTablePaginationProps> = ({
  pagination,
  loading,
}) => {
  const [, updateStore] = useStoreValue();

  const orderAmountRange = getNumberRange({
    pageNumber: pagination?.current_page_number,
    pageSize: pagination?.response_page_size,
  });

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    updateStore({
      currentPageNumber: value,
    });
  };

  if (loading) {
    return (
      <div className="mt-10">
        <Skeleton width="100%" height="32px" />
      </div>
    );
  }

  return (
    <div className="flex justify-between items-center mt-10">
      <div className="comestri-subtext-info">{`${orderAmountRange.start} - ${
        pagination?.total_number_of_records
          ? orderAmountRange.end > pagination?.total_number_of_records
            ? pagination?.total_number_of_records
            : orderAmountRange.end
          : 0
      } of ${pagination?.total_number_of_records ?? 0}`}</div>
      <Pagination
        disabled={loading}
        count={pagination?.total_page_number || 1}
        page={pagination?.current_page_number || 1}
        onChange={handleChange}
        color="primary"
      />
    </div>
  );
};
