import _set from "lodash/set";
import _get from "lodash/get";
import { PropertyPath } from "lodash";

export const setSessionStorage = (
  key: string,
  data?: any,
  path?: PropertyPath
) => {
  let value = { ...data };
  // replace/create data at specific path in the stored object
  if (path) {
    const json = getSessionStorage(key) || {};
    value = _set(json, path, data);
  }

  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getSessionStorage = (key: string, path?: undefined) => {
  const data: any = sessionStorage.getItem(key);
  try {
    // attempt to parse data
    const json = JSON.parse(data);
    // get data at specific path in the stored object OR entire object
    return path ? _get(json, path) : json;
  } catch (e) {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.error(
        `getSessionStorage: cannot use @path: ${path} there is no JSON data at ${key}.${path}`
      );
    }
    // if parsing fails return raw data
    return data || null;
  }
};
