import {
  Button,
  Dialog,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import { CANCELLATION_MESSAGE, DEFAULT_MESSAGE } from "../../../constant";
import { useStoreValue } from "../../../store";

type OrderTableConfirmPopupProps = {
  open: boolean;
  orderAmount: number;
  handleToggleConfirmCancellation: () => void;
  handleCancelOrders: () => void;
};

export const OrderTableConfirmPopup: FC<OrderTableConfirmPopupProps> = ({
  open,
  orderAmount,
  handleToggleConfirmCancellation,
  handleCancelOrders,
}) => {
  const theme = useTheme();
  const [storeState, updateStore] = useStoreValue();

  const handleSelectCancellationMessage = (event: SelectChangeEvent) => {
    updateStore({ orderCancellationReason: event.target.value });
  };

  return (
    <Dialog open={open}>
      <div className="p-6 font-semibold text-xl leading-6">
        {`Cancel ${orderAmount} Orders`}
      </div>
      <Divider />
      <div className="p-6">
        <p className="text-sm">
          This will remove the order/s and related information from Comestri.{" "}
          {` `}
          <span
            className="font-semibold"
            style={{ color: theme.palette.secondary.main }}
          >
            This is a permanent action.
          </span>
        </p>

        <p className="pt-6 pb-2 text-sm">Enter a reason code</p>

        <Select
          variant="outlined"
          size="small"
          color="primary"
          fullWidth
          value={storeState.orderCancellationReason || DEFAULT_MESSAGE}
          onChange={handleSelectCancellationMessage}
        >
          {CANCELLATION_MESSAGE.map((message, key) => {
            return (
              <MenuItem key={key} value={message.value}>
                {message.label}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <Divider />
      <div className="flex justify-end p-6">
        <div className="mr-2">
          <Button
            color="inherit"
            type="submit"
            size="small"
            variant="contained"
            disableRipple
            disableElevation
            onClick={handleToggleConfirmCancellation}
          >
            Back
          </Button>
        </div>
        <div>
          <Button
            color="secondary"
            type="submit"
            size="small"
            variant="contained"
            disableRipple
            disableElevation
            onClick={handleCancelOrders}
          >
            {`Cancel ${orderAmount} Orders`}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
