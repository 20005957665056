import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { Auth } from "aws-amplify";

const tokenFetch = (uri: RequestInfo, options: any) => {
  return Auth.currentSession()
    .then((session: any) => {
      options.headers.idToken = session["idToken"].jwtToken;
      options.headers.refreshToken = session["refreshToken"].token;
      options.headers.accessToken = session["accessToken"].jwtToken;
      options.headers.authorization = `Bearer ${session["idToken"].jwtToken},token_use=id_token`;

      window.sessionStorage.setItem(
        "authorization",
        `Bearer ${session["idToken"].jwtToken}`
      );
      return fetch(uri, options);
    })
    .catch((error) => null);
};

export const client = new ApolloClient({
  link: createHttpLink({
    uri: process.env.REACT_APP_ADMIN_CENTER,
    // @ts-ignore
    fetch: tokenFetch,
  }),
  cache: new InMemoryCache(),
});
