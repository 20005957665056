import {useEffect} from "react";
import {useSnackbar, OptionsObject} from "notistack";
import {useStoreValue} from "../store";
import {
  CancellationFail,
  CancellationPayload,
  CancellationSuccess,
} from "../types/cancellation.type";
import {Order, SelectedOrderSKUs, SKU} from "../types/order.type";
import {Api} from "../_api";
import {useOrders} from "./useOrders";

export const useCancellation = () => {
  const [storeState, updateStore] = useStoreValue();
  const {fetchOrders} = useOrders();
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    // when got cancellation result we need to format the data to save orderCancellationFails
    getResultsFromCancellation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeState.orderCancellationPayload]);

  const confirmCancelOrders = async (skusByOrders: SelectedOrderSKUs) => {
    updateStore({
      orderCancellationLoading: true,
    });

    Promise.all(
      Object.keys(skusByOrders).map(async (order_id) => {
        const thisOrdersSkus: SKU[] = storeState.orderPageData.orders.find(
          (data: Order) => data.order_id === order_id
        )?.skus;

        const skus = skusByOrders[order_id].map((sku) => {
          const result = thisOrdersSkus.find((item) => item.web_sku_id === sku);
          return result;
        }) as SKU[];

        return Api.deleteOrder({
          order_id,
          skus,
          cancel_reason: storeState.orderCancellationReason || null,
        });
      })
    )
      .then((response) => {
        const result = response.map((r) => r.data.data);
        const alertObj: any = {
          alertOpen: true,
          alertMessage: "",
          alertType: "success",
        };

        const successOrders = result
          .map((r) => {
            if (r.success_cases.length) {
              return r.order_id;
            }
          })
          .filter((x) => x !== undefined);

        const failOrders = result
          .map((r) => {
            if (r.fail_cases.length) {
              return r.order_id;
            }
          })
          .filter((x) => x !== undefined);


        let alertMessage: string;
        let alertOptions: OptionsObject;
        if (successOrders.length) {
          alertMessage = `${successOrders.length} ${successOrders.length === 1 ? "order was" : "orders were"
            } successfully cancelled.`;
          alertOptions = {variant: "success"};
          enqueueSnackbar(alertMessage, alertOptions);
        }

        if (failOrders.length) {
          alertMessage = `${failOrders.length} ${failOrders.length === 1 ? "order" : "orders"
            } could not be cancelled.`;
          alertOptions = {variant: "error"};
          enqueueSnackbar(alertMessage, alertOptions);
        }

        updateStore({
          ...alertObj,
          orderCancellationPayload: [
            ...storeState.orderCancellationPayload,
            ...result,
          ],
          orderCancellationLoading: false,
        });
      })
      .catch((error) => {
        console.error("🔊  ====================> error", error);
        updateStore({
          alertOpen: true,
          alertType: "error",
          alertMessage: "Network Issue",
          orderCancellationLoading: false,
          orderCancellationError: error,
        });
      })
      .finally(() => {
        fetchOrders({
          page_size: storeState.currentPageSize,
          page_number: storeState.currentPageNumber,
          to_order_placed_date: storeState.currentFilterDate,
          web_sku_id: storeState.currentFilterSkus,
        });
      });
  };

  const getResultsFromCancellation = () => {
    const failSkusByOrderId: {
      [order_id: string]: CancellationFail[];
    } = {};

    const successSkusByOrderId: {
      [order_id: string]: CancellationSuccess[];
    } = {};

    if (storeState.orderCancellationPayload.length) {
      storeState.orderCancellationPayload.forEach(
        (order: CancellationPayload) => {
          failSkusByOrderId[order.order_id] = order.fail_cases;
          successSkusByOrderId[order.order_id] = order.success_cases;
        }
      );

      //@********************************
      //@ Tip: Remove orders locally only
      //@********************************
      // const copyOrderPageData = storeState.orderPageData;
      // const newOrderPageData = copyOrderPageData.orders.map((order: Order) => {
      //   const currentOrderSkus = successSkusByOrderId[order.order_id];
      //   if (currentOrderSkus) {
      //     if (order.skus.length === currentOrderSkus.length) {
      //       return null;
      //     } else {
      //       const skusNeedToRemove = currentOrderSkus.map((item) => {
      //         return item.sku.web_sku_id;
      //       });
      //       order.skus = order.skus.filter((sku) => {
      //         return skusNeedToRemove.indexOf(sku.web_sku_id) === -1;
      //       });
      //       return order;
      //     }
      //   }
      //   return order;
      // });

      // const orderPageData = {
      //   ...storeState.orderPageData,
      //   orders: newOrderPageData.filter((ele: Order) => ele),
      // };

      updateStore({
        // orderPageData,
        orderCancellationFails: failSkusByOrderId,
      });
    }
  };

  return {
    confirmCancelOrders,
  };
};
