import { createContext, useContext, useReducer } from "react";
import { setSessionStorage } from "../utils/sessionStorage";

export const storageStateKey = "comestri";

const reducer = (existingState: any, newState: any) => {
  const state = {
    ...existingState,
    ...newState,
  };

  if (process.env.REACT_APP_ENV === "dev") {
    setSessionStorage(storageStateKey, state);
  }

  return state;
};

const StoreContext = createContext<any>({});

type StoreProviderProps = {
  initialState: any;
  children: React.ReactNode;
};

export const StoreProvider: React.FC<StoreProviderProps> = ({
  initialState,
  children,
}): any => {
  const value = useReducer(reducer, initialState);
  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};

export const useStoreValue = () => useContext(StoreContext);
