import Amplify, { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { useState } from "react";
import { useStoreValue } from "../store";

interface LoginWithAmplifyType {
  onSuccess: (user: CognitoUser) => void;
  onError?: (error: any) => void;
}

const region = {
  id: process.env.REACT_APP_REGION_ID,
  title: process.env.REACT_APP_REGION_TITLE,
  description: process.env.REACT_APP_REGION_DESCRIPTION,
  awsRegion: process.env.REACT_APP_REGION_AWS_REGION,
  region: process.env.REACT_APP_REGION_REGION,
  userPoolId: process.env.REACT_APP_REGION_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_REGION_USER_POOL_WEB_CLIENT_ID,
};

export const AuthBaseFile = (otherConfig?: { [key: string]: string }) => {
  Amplify.configure({
    Auth: {
      region: region.region,
      userPoolId: region.userPoolId,
      userPoolWebClientId: region.userPoolWebClientId,
      ...otherConfig,
    },
  });
};

const useAmplifyLogin = () => {
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [, updateStore] = useStoreValue();

  const loginWithAmplify = async ({
    onSuccess,
    onError,
  }: LoginWithAmplifyType) => {
    AuthBaseFile({ authenticationFlowType: "USER_PASSWORD_AUTH" });
    try {
      const cognitoUser = await Auth.signIn(email, password);
      if (cognitoUser.challengeName) {
        updateStore({
          alertOpen: true,
          alertMessage:
            "Sorry, you need to update your password to display the tenants.",
          alertType: "error",
        });
      }
      onSuccess && onSuccess(cognitoUser);
    } catch (error) {
      onError && onError(error);
    }
  };

  return {
    email,
    setEmail,
    emailError,
    setEmailError,
    password,
    setPassword,
    passwordError,
    setPasswordError,
    loginWithAmplify,
  };
};

const useFetchAuth = () => {
  const fetchAuthAsync = async ({
    onSuccess,
    onError,
  }: LoginWithAmplifyType) => {
    AuthBaseFile();
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      if (cognitoUser) {
        onSuccess && onSuccess(cognitoUser);
      } else {
        onError && onError("authentication failed..");
      }
    } catch (error) {
      onError && onError(error);
    }
  };

  return { fetchAuthAsync };
};

export { useAmplifyLogin, useFetchAuth };
