const getNumberRange = ({
  pageNumber,
  pageSize,
}: {
  pageNumber?: number;
  pageSize?: number;
}) => {
  if (pageNumber !== undefined && pageSize !== undefined) {
    const start = (pageNumber - 1) * pageSize + 1;
    const end = pageSize * pageNumber;
    return { start, end };
  }

  return {
    start: 0,
    end: 0,
  };
};

export default getNumberRange;
