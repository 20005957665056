import { Dispatch, FC, SetStateAction } from "react";
import { v4 as uuidv4 } from "uuid";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { ClockIcon } from "../components/Icons";
import moment from "moment";

type DateTimerPickerProps = {
  id?: string;
  time: Date | null;
  disabled: boolean;
  setTime: Dispatch<SetStateAction<Date | null>>;
};

const DateTimerPicker: FC<DateTimerPickerProps> = ({
  id,
  time,
  disabled,
  setTime,
}) => {
  const placeholder = moment(new Date()).format("DD MMM, yyyy hh:mm a");
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="data-timer-picker">
        <DateTimePicker
          inputFormat="dd MMM, yyyy hh:mm a"
          disabled={disabled}
          components={{
            OpenPickerIcon: ClockIcon,
          }}
          InputAdornmentProps={{
            position: "start",
            sx: { paddingLeft: "0px" },
          }}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
            return (
              <TextField
                {...params}
                id={id || `data-timer-picker-${uuidv4()}`}
                fullWidth
                hiddenLabel
                variant="outlined"
                size="small"
                color="primary"
                inputProps={{
                  ...params.inputProps,
                  placeholder,
                }}
              />
            );
          }}
          value={time}
          maxDateTime={new Date()}
          onChange={(newValue: any) => {
            setTime(newValue);
          }}
        />
      </div>
    </LocalizationProvider>
  );
};

export default DateTimerPicker;
