import { OrdersTopBar } from "./components/orders-top-bar";
import { OrdersFilterBar } from "./components/orders-filter-bar";
import { OrdersControlBar } from "./components/orders-contorl-bar";
import { OrdersTable } from "./components/orders-table";
import { OrdersTablePagination } from "./components/orders-table-pagination";
import { useEffect, useState } from "react";
import { SelectedOrderSKUs } from "../../types/order.type";
import { useOrders } from "../../hooks/useOrders";
import { useCancellation } from "../../hooks/useCancellation";
import { useStoreValue } from "../../store";
import {
  defaultAlertStore,
  defaultCancellationOrderStore,
  defaultOrderStore,
  defaultPaginationStore,
  defaultTenantStore,
  defaultTenderStore,
} from "../../store/initialState";
import { OrderTableConfirmPopup } from "./components/order-table-confirm-popup";

type OrdersProps = {};

export const Orders: React.FC<OrdersProps> = () => {
  const [storeState, updateStore] = useStoreValue();
  const { confirmCancelOrders } = useCancellation();
  useOrders();

  // @feature: ?cancel-order=true
  // const mockCancelOrders = useSearchParam("cancel-order");

  // when page rerender, it should clear whole store
  useEffect(() => {
    updateStore({
      ...defaultOrderStore,
      ...defaultPaginationStore,
      ...defaultCancellationOrderStore,
      ...defaultTenderStore,
      ...defaultAlertStore,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedSKUs, setSelectedSKUs] = useState<{} | SelectedOrderSKUs>({});
  const [confirmCancellation, setConfirmCancellation] =
    useState<boolean>(false);

  // when order table rerender, it should clear selected skus
  useEffect(() => {
    setSelectedSKUs({});
  }, [
    storeState.currentPageNumber,
    storeState.currentFilterDate,
    storeState.currentFilterSkus,
  ]);

  // get orders amount
  const orderAmount = Object.keys(selectedSKUs).length;

  const handleCancelOrders = () => {
    confirmCancelOrders(selectedSKUs);
    setSelectedSKUs({});
    handleToggleConfirmCancellation();
  };

  const handleToggleConfirmCancellation = () => {
    updateStore({
      orderCancellationReason: "",
    });
    setConfirmCancellation((preState) => !preState);
  };

  return (
    <div className="flex flex-col h-full">
      <OrdersTopBar />
      <div className="flex flex-col flex-grow overflow-auto py-7 px-32">
        <OrdersFilterBar loading={storeState.orderPageLoading} />
        <OrdersControlBar
          pagination={storeState.orderPageData?.pagination}
          orderAmount={orderAmount}
          loading={storeState.orderPageLoading}
          handleToggleConfirmCancellation={handleToggleConfirmCancellation}
        />
        <OrdersTable
          loading={storeState.orderPageLoading}
          data={storeState.orderPageData?.orders}
          cancellationFailData={storeState.orderCancellationFails}
          selectedSKUs={selectedSKUs}
          setSelectedSKUs={setSelectedSKUs}
        />
        <OrdersTablePagination
          loading={storeState.orderPageLoading}
          pagination={storeState.orderPageData?.pagination}
        />
      </div>

      <OrderTableConfirmPopup
        open={confirmCancellation}
        orderAmount={orderAmount}
        handleToggleConfirmCancellation={handleToggleConfirmCancellation}
        handleCancelOrders={handleCancelOrders}
      />
    </div>
  );
};
