import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useStoreValue } from "../store";

export type TenantType = {
  id: string;
  name: string;
};

export const useFetchTenant = () => {
  const ADMIN_USER_INFO = gql`
    query currentUser {
      currentUser {
        tenants {
          id
          name
        }
      }
    }
  `;

  const [, updateStore] = useStoreValue();
  const [tenants, setTenants] = useState<TenantType[]>([]);
  const [fetchUserSetting, { loading, refetch }] = useLazyQuery(
    ADMIN_USER_INFO,
    {
      onCompleted: (result) => {
        updateStore({
          alertOpen: true,
          alertMessage: "Fetch Tenants Successful",
          alertType: "success",
        });
        setTenants(result.currentUser.tenants);
      },
      onError: (ApolloError) => {
        console.error(ApolloError);
      },
    }
  );

  useEffect(() => {
    fetchUserSetting();
  }, [fetchUserSetting]);

  return {
    tenants,
    loading,
    refetch,
  };
};
