export const ErrorIcon = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.86001 2.57347L1.21335 12.0001C1.09693 12.2018 1.03533 12.4303 1.03467 12.6631C1.03402 12.896 1.09434 13.1249 1.20963 13.3272C1.32492 13.5294 1.49116 13.698 1.69182 13.816C1.89247 13.9341 2.12055 13.9976 2.35335 14.0001H13.6467C13.8795 13.9976 14.1076 13.9341 14.3082 13.816C14.5089 13.698 14.6751 13.5294 14.7904 13.3272C14.9057 13.1249 14.966 12.896 14.9654 12.6631C14.9647 12.4303 14.9031 12.2018 14.7867 12.0001L9.14001 2.57347C9.02117 2.37754 8.85383 2.21555 8.65414 2.10313C8.45446 1.9907 8.22917 1.93164 8.00001 1.93164C7.77086 1.93164 7.54557 1.9907 7.34588 2.10313C7.1462 2.21555 6.97886 2.37754 6.86001 2.57347V2.57347Z"
        stroke={props.color || "#E54937"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 6V8.66667"
        stroke={props.color || "#E54937"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11.3335H8.00667"
        stroke={props.color || "#E54937"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const CheckboxIcon = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="3.5"
        fill="white"
        stroke="#D5D5D5"
      />
    </svg>
  );
};
export const CheckboxChecked = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="3.5"
        fill="#040001"
        stroke="#D5D5D5"
      />
      <path
        d="M12 5L6.5 10.5L4 8"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const CheckboxIndeterminate = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="3.5"
        fill="#040001"
        stroke="#D5D5D5"
      />
      <path
        d="M4.5 8H11.5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const ArrowIcon = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12L10 8L6 4"
        stroke={props.color || "#374151"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const SearchIcon = (props: any) => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66665 7.33325C2.66665 4.75592 4.75598 2.66659 7.33331 2.66659C9.91064 2.66659 12 4.75592 12 7.33325C12 8.59055 11.5028 9.73172 10.6942 10.5708C10.6714 10.5884 10.6495 10.6076 10.6286 10.6285C10.6077 10.6494 10.5885 10.6714 10.5709 10.6942C9.73178 11.5027 8.59061 11.9999 7.33331 11.9999C4.75598 11.9999 2.66665 9.91058 2.66665 7.33325ZM11.0784 12.0212C10.052 12.8423 8.74999 13.3333 7.33331 13.3333C4.0196 13.3333 1.33331 10.647 1.33331 7.33325C1.33331 4.01954 4.0196 1.33325 7.33331 1.33325C10.647 1.33325 13.3333 4.01954 13.3333 7.33325C13.3333 8.74993 12.8423 10.0519 12.0212 11.0784L14.4714 13.5285C14.7317 13.7889 14.7317 14.211 14.4714 14.4713C14.211 14.7317 13.7889 14.7317 13.5286 14.4713L11.0784 12.0212Z"
        fill={props.color || "#585858"}
      />
    </svg>
  );
};

export const CloseIcon = (props: any) => {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 3L3 9"
        stroke={props.color || "white"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 3L9 9"
        stroke={props.color || "white"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const ClockIcon = (props: any) => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 2.66669H3.33333C2.59695 2.66669 2 3.26364 2 4.00002V13.3334C2 14.0697 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0697 14 13.3334V4.00002C14 3.26364 13.403 2.66669 12.6667 2.66669Z"
        stroke={props.color || "#585858"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6667 1.33331V3.99998"
        stroke={props.color || "#585858"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33325 1.33331V3.99998"
        stroke={props.color || "#585858"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 6.66669H14"
        stroke={props.color || "#585858"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
